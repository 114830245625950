import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './../../themes';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const SMTPSchema = Yup.object().shape({
    from: Yup.string().required(),
    host: Yup.string().required(),
    port: Yup.number().required(),
});

const SMTPConfig = props => {
    const { onSubmit, values, onVerify } = props;
    const classes = useStyles();
    const [valid, isValid] = React.useState(false);
    const [action, setAction] = React.useState(null);
    const [validationError, setValidationError] = React.useState(null);

    const initialValues = values || {
        name: '',
        from: '',
        host: '',
        port: 25,
        user: '',
        password: '',
        secure: false,
    };

    const _submitHandler = async val => {
        if (onSubmit) {
            await onSubmit(val);
        }
    };

    const _verifyHandler = async values => {
        setValidationError(null);
        setAction('verify');
        isValid(false);
        if (onVerify) {
            try {
                const res = await onVerify(values);
                if (res.error) {
                    setValidationError('Verification failed!');
                } else {
                    isValid(true);
                }
            } catch {
                setValidationError('Verification failed!');
            }
        }
        setAction(null);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SMTPSchema}
            onSubmit={(values, actions) => {
                _submitHandler(values);
                actions.setSubmitting(true);
            }}
        >
            {({
                handleChange,
                values,
                isSubmitting,
                isValidating,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit} id="smtp-form">
                    <TextField
                        id="smtp_name"
                        name="name"
                        onChange={handleChange}
                        defaultValue={values.name}
                        error={touched.name && errors.name}
                        fullWidth
                        label="Name"
                        className={classes.gutterBottom}
                        variant="outlined"
                        disabled={values._id}
                    />
                    <TextField
                        id="smtp_from"
                        name="from"
                        onChange={handleChange}
                        defaultValue={values.from}
                        error={touched.from && errors.from}
                        fullWidth
                        label="From"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <TextField
                        id="smtp_host"
                        name="host"
                        onChange={handleChange}
                        defaultValue={values.host}
                        error={touched.host && errors.host}
                        fullWidth
                        label="Host"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <TextField
                        id="smtp_port"
                        name="port"
                        onChange={handleChange}
                        error={errors.port}
                        defaultValue={values.port}
                        fullWidth
                        label="Port"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <TextField
                        id="smtp_user"
                        name="user"
                        onChange={handleChange}
                        error={errors.user}
                        fullWidth
                        label="User"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <TextField
                        id="smtp_user"
                        name="password"
                        onChange={handleChange}
                        error={errors.password}
                        fullWidth
                        label="Password"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch onChange={handleChange} name="secure" />
                            }
                            label="Secure"
                        />
                    </FormGroup>
                    <FormHelperText className={classes.gutterBottom}>
                        Connection is secure
                    </FormHelperText>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => _verifyHandler(values)}
                        className={classes.gutterBottom}
                        disabled={action === 'verify'}
                    >
                        Test Connection
                    </Button>
                    {validationError && (
                        <Alert
                            severity="error"
                            className={classes.gutterBottom}
                        >
                            {validationError}
                        </Alert>
                    )}
                    {valid && (
                        <Alert
                            severity="success"
                            className={classes.gutterBottom}
                        >
                            Verification ok
                        </Alert>
                    )}
                </form>
            )}
        </Formik>
    );
};

SMTPConfig.propTypes = {
    onSubmit: PropTypes.func,
    onVerify: PropTypes.func,
    values: PropTypes.array,
};

export default SMTPConfig;
