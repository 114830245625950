import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Dropzone from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    widget: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(2),
        color: 'white',
        height: 120,
        overflow: 'hidden',
        cursor: 'pointer',
    },
    widgetDrag: {
        backgroundColor: theme.palette.secondary.dark,
    },
    icon: {
        position: 'absolute',
        height: 100,
    },
    body: {
        marginLeft: 60,
    },
    source: {
        minHeight: 300,
    },
}));

const ConverterWidget = () => {
    const classes = useStyles();
    // reasult for conversion
    const [result, setResult] = React.useState(null);
    const [disabled, setDisabled] = React.useState(false);
    const [isDragActive, setIsDragActive] = React.useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setDisabled(true);
        // Do something with the files
        const f = acceptedFiles[0];
        const formData = new FormData();
        formData.append('pdf', f);

        fetch('/api/pdf2json', {
            method: 'POST',
            body: formData,
        })
            .then(res => res.json())
            .then(res => {
                setResult(res);
            })
            .catch(() => {
                alert('Conversion failed.');
            });
    }, []);

    const handleClose = () => {
        setDisabled(false);
        setResult(null);
        setIsDragActive(false);
    };

    return (
        <Dropzone
            onDrop={onDrop}
            disabled={disabled}
            onDragEnter={() => setIsDragActive(true)}
            onDragLeave={() => setIsDragActive(false)}
        >
            {({ getRootProps, getInputProps }) => (
                <Paper
                    className={clsx(
                        classes.widget,
                        isDragActive ? classes.widgetDrag : null
                    )}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    <div className={classes.icon}>
                        <CloudUploadIcon fontSize="large" />
                    </div>
                    <div className={classes.body}>
                        <Typography variant="h3">Converter</Typography>
                        <Typography variant="body2">
                            Please drag a pdf form here or click on this button
                            to <strong>convert</strong> it{' '}
                            <strong>to json</strong>.
                        </Typography>
                    </div>
                    <Dialog open={Boolean(result)} onClose={handleClose}>
                        <DialogTitle>Conversion result</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <SyntaxHighlighter
                                    language="json"
                                    style={docco}
                                    className={classes.source}
                                >
                                    {JSON.stringify(result)}
                                </SyntaxHighlighter>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Paper>
            )}
        </Dropzone>
    );
};

ConverterWidget.defaultProps = {};

ConverterWidget.propTypes = {};

export default ConverterWidget;
