import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useStyles } from './../../themes';
import AceEditor from 'react-ace';
import Tester from './tester';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';

const MailSchema = Yup.object().shape({
    key: Yup.string().required(),
    subject: Yup.string().required(),
    text: Yup.string().required(),
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.number,
};

const MailEdit = props => {
    const { onSubmit, onFocus, values } = props;
    const classes = useStyles();
    const [tab, setTab] = React.useState(0);

    const initialValues = values
        ? { ...values }
        : {
              key: '',
              sample: null,
              type: 'mail',
              subject: null,
              text: null,
              html: null,
          };

    const _handleTabChange = (event, val) => {
        setTab(val);
    };

    const _submitHandler = val => {
        if (onSubmit) {
            onSubmit(val);
        }
    };

    const _focusHandler = field => {
        if (onFocus) {
            onFocus(field);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={MailSchema}
            onSubmit={(values, actions) => {
                _submitHandler(values);
                actions.setSubmitting(true);
            }}
        >
            {({
                handleChange,
                values,
                isSubmitting,
                isValidating,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
            }) => (
                <form onSubmit={handleSubmit} id="mailer-form">
                    <TextField
                        id="template_key"
                        name="key"
                        inputProps={{
                            onFocus: ev => _focusHandler(ev.target.name),
                        }}
                        onChange={handleChange}
                        error={touched.key && errors.key}
                        fullWidth
                        defaultValue={values.key}
                        label="Key"
                        className={classes.gutterBottom}
                        variant="outlined"
                        /* disabled when key is provided as prop */
                        disabled={values && values._id}
                    />
                    <TextField
                        id="template_description"
                        name="description"
                        inputProps={{
                            onFocus: ev => _focusHandler(ev.target.name),
                        }}
                        onChange={handleChange}
                        defaultValue={values.description}
                        fullWidth
                        multiline
                        rows="5"
                        label="Description"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <TextField
                        id="template_subject"
                        name="subject"
                        error={touched.subject && errors.subject}
                        defaultValue={values.subject}
                        onChange={handleChange}
                        inputProps={{
                            onFocus: ev => _focusHandler(ev.target.name),
                        }}
                        fullWidth
                        label="Subject"
                        className={classes.gutterBottom}
                        variant="outlined"
                    />
                    <hr />
                    <Tabs
                        aria-label="Mail Body"
                        value={tab}
                        className={classes.gutterBottom}
                        onChange={_handleTabChange}
                    >
                        <Tab label="Text" />
                        <Tab label="HTML" />
                        <Tab label="Test-Data" />
                        <Tab label="Preview" disabled />
                        <Tab label="Send" />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <TextField
                            id="template_text"
                            name="text"
                            defaultValue={values.text}
                            error={touched.text && errors.text}
                            onChange={handleChange}
                            inputProps={{
                                onFocus: ev => _focusHandler(ev.target.name),
                            }}
                            fullWidth
                            label="Text"
                            className={classes.gutterBottom}
                            variant="outlined"
                            multiline
                            rows={10}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <AceEditor
                            mode="html"
                            theme="monokai"
                            onChange={val => setFieldValue('html', val)}
                            width="auto"
                            name="html"
                            onFocus={() => _focusHandler('html')}
                            value={values.html}
                            defaultValue={values.html}
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                                showPrintMargin: false,
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <AceEditor
                            mode="json"
                            theme="github"
                            onFocus={() => _focusHandler('sample')}
                            onChange={val => setFieldValue('sample', val)}
                            width="auto"
                            name="sample"
                            value={values.sample}
                            defaultValue={values.sample}
                            editorProps={{ $blockScrolling: true }}
                            setOptions={{
                                showPrintMargin: false,
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                enableSnippets: true,
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        Preview
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <Tester
                            values={{
                                template: values.key,
                                test_data: values.sample,
                            }}
                        />
                    </TabPanel>
                </form>
            )}
        </Formik>
    );
};

MailEdit.defaultProps = {};

MailEdit.propTypes = {
    onSubmit: PropTypes.func,
    onFocus: PropTypes.func,
    values: PropTypes.object,
};

export { MailEdit };

export default MailEdit;
