import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../../themes';

const Page = props => {
    const { children } = props;
    const classes = useStyles();
    return <div className={classes.content}>{children}</div>;
};

Page.defaultProps = {};

Page.propTypes = {};

export default Page;
