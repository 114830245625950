import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditTemplate from './../../components/templates/edit';
import {
    getTemplate,
    postUpload,
    getUpload,
    updateTemplate,
} from './../../store/actions';
import { Loader } from '@hobex/react-material';

const EditTemplateContainer = props => {
    const {
        template,
        getTemplate,
        postUpload,
        getUpload,
        saving,
        updateTemplate,
        id,
    } = props;
    const [upload, setUpload] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [uploading, setUploading] = useState(false);

    // initial loading on id change
    useEffect(() => {
        const fetch = async () => {
            setLoaded(false);
            getTemplate();
        };

        fetch();
    }, [id]);

    useEffect(() => {
        const fetch = async () => {
            console.log('fetch template', template);
            // fetch upload on template change and doc available
            if (template !== null) {
                // there is always a doc
                const {
                    payload: { data },
                } = await getUpload(template.doc);
                setUpload(data);
                setLoaded(true);
            } else {
                setUpload(null);
                setLoaded(false);
            }
        };
        fetch();
    }, [template]);

    // intermediate to clear files. don't use the store here. it's easier to clear like this.
    const onUpload = files => {
        if (files === null) {
            setUpload(null);
        } else {
            setUploading(true);
            postUpload(files)
                .then(res => res.payload.data)
                .then(data => {
                    setUpload(data);
                    setUploading(false);
                });
        }
    };

    const onSubmit = template => {
        console.log(template);
        updateTemplate(template);
    };

    // show the loader if not loaded
    if (!loaded) {
        return <Loader />;
    }

    // Return the connected template
    return (
        <EditTemplate
            template={template}
            onSubmit={onSubmit}
            onUpload={onUpload}
            saving={saving}
            upload={upload}
            uploading={uploading}
        />
    );
};

EditTemplateContainer.propTypes = {
    id: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    return {
        template: state.template,
        saving: state.saving,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTemplate: () => {
            return dispatch(getTemplate(ownProps.id));
        },
        postUpload: files => dispatch(postUpload(files)),
        getUpload: id => dispatch(getUpload(id)),
        // map the provided data to action data
        updateTemplate: data =>
            dispatch(
                updateTemplate({
                    _id: ownProps.id,
                    doc: data.template.id,
                    ...data,
                })
            ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditTemplateContainer);
