import { makeStyles } from '@material-ui/core/styles';

const theme = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    link: {
        cursor: 'pointer',
    },
    paperHeader: {
        padding: theme.spacing(2),
        backgroundColor: '#2f323a',
        color: '#FFF',
    },
    gutterRight: {
        marginRight: theme.spacing(2),
    },
    gutterBottom: {
        marginBottom: theme.spacing(2),
    },
    breadcrumb: {
        lineHeight: '36px',
        color: '#FFF',
    },
    saveButton: {
        marginBottom: theme.spacing(2),
        lineHeight: '48px',
        fontSize: '24px',
    },
    content: {
        flex: 1,
        marginLeft: 0,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
    },
    right: {
        justifyContent: 'right',
    },
    rightFloat: {
        marginLeft: 'auto',
    },
});

const useStyles = makeStyles(theme);

export { useStyles, theme };
export default useStyles;
