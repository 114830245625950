import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CommentIcon from '@material-ui/icons/Comment';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
const menu = [
    {
        title: 'Home',
        uri: '/',
        component: Link,
    },
    {
        title: 'Documents',
        uri: '/documents',
        icon: <DescriptionIcon fontSize="small" />,
        component: Link,
    },
    {
        title: 'Parser',
        uri: '/parser',
        icon: <DescriptionIcon fontSize="small" />,
        component: Link,
    },
    {
        title: 'Designer',
        uri: '/designer',
        icon: <ListItemText fontSize="small" />,
        component: Link,
    },
    {
        title: 'Messages',
        uri: '/mailer',
        component: Link,
    },
    {
        title: 'Documentation',
        icon: <CommentIcon />,
        uri: '/docs',
    },
];

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menu: {
        display: 'inline',
    },
}));
const Menu = props => {
    const classes = useStyles();

    const items = [];
    for (let item of menu) {
        items.push(
            <MenuItem
                classes={{ root: classes.menu }}
                to={item.uri}
                button
                component={item.component || 'a'}
                href={!item.component ? item.uri : null}
                alignItems="flex-start"
            >
                <Typography variant="inherit">{item.title}</Typography>
            </MenuItem>
        );
    }

    return <MenuList disablePadding={true}>{items}</MenuList>;
};

Menu.defaultProps = {};

Menu.propTypes = {
    items: PropTypes.array,
};
export default Menu;
