/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { getTemplates, getStats } from './../../store/actions';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PageviewIcon from '@material-ui/icons/Pageview';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import DetailRow from './detailRow';
import EditIcon from '@material-ui/icons/Edit';
import MailIcon from '@material-ui/icons/Mail';
import Moment from 'react-moment';
import { DeleteButton } from '@hobex/react-material/dist/buttons';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import CloneButton from '../buttons/clone';
import LockIcon from '@material-ui/icons/Lock';
import { theme as rootTheme } from '../../themes';

const useStyles = makeStyles(theme => ({
    ...rootTheme,
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    toolbar: {
        textAlign: 'right',
    },
    ease: {},
    publicChip: {
        marginLeft: theme.spacing(2),
    },
}));

const TypeTable = props => {
    const { templates, getTemplates, getStats, onEdit } = props;
    const classes = useStyles();

    console.log('templates=', templates);
    const deleteTemplate = template => {
        fetch('/api/templates/' + template._id, {
            method: 'DELETE',
        }).finally(() => {
            getTemplates();
            getStats();
        });
    };

    if (!templates) {
        getTemplates();
        return <div></div>;
    }

    const handleTree = () => {
        console.log('handletree');
    };

    const _cloneHandler = (template, key) => {
        const newTemplate = {
            ...template,
            template: template.doc,
            key,
            name: key,
            _id: undefined,
        };
        fetch('/api/templates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newTemplate),
        }).then(res => {
            getTemplates();
        });
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <MaterialTable
                    isLoading={!templates}
                    columns={[
                        {
                            title: 'Owner',
                            render: rowData => {
                                return (
                                    <Avatar
                                        src={rowData.owner.avatar}
                                        title={rowData.owner.displayName}
                                    />
                                );
                            },
                            cellStyle: {
                                width: 60,
                            },
                        },
                        {
                            title: 'Key',
                            render: rowData => {
                                return (
                                    <ListItem>
                                        <ListItemAvatar>
                                            {rowData.type === 'parse' && (
                                                <Avatar title="Parse">
                                                    <PageviewIcon />
                                                </Avatar>
                                            )}
                                            {rowData.type === 'render' && (
                                                <Avatar title="Render">
                                                    <ImportExportIcon />
                                                </Avatar>
                                            )}
                                            {rowData.type === 'form' && (
                                                <Avatar title="Form">
                                                    <ListAltIcon />
                                                </Avatar>
                                            )}
                                            {rowData.type === 'mail' && (
                                                <Avatar title="Mail">
                                                    <MailIcon />
                                                </Avatar>
                                            )}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={rowData.key}
                                            secondary={
                                                <>
                                                    <div
                                                        className={
                                                            classes.gutterBottom
                                                        }
                                                        style={{
                                                            marginBottom: '8px',
                                                        }}
                                                    >
                                                        <Moment fromNow>
                                                            {rowData.createDate}
                                                        </Moment>
                                                        {rowData.public && (
                                                            <Tooltip title="Form is public available">
                                                                <Chip
                                                                    color="secondary"
                                                                    label="Public"
                                                                    size="small"
                                                                    className={
                                                                        classes.publicChip
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {rowData.type ===
                                                            'render' &&
                                                            rowData.storage ===
                                                                'grid' && (
                                                                <Tooltip title="Please update template to use s3 storage">
                                                                    <Chip
                                                                        color="secondary"
                                                                        label="Action required!"
                                                                        size="small"
                                                                        style={{
                                                                            marginRight:
                                                                                '8px',
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        {rowData.readonly && (
                                                            <Tooltip title="Template is readonly">
                                                                <Chip
                                                                    icon={
                                                                        <LockIcon fontSize="small" />
                                                                    }
                                                                    size="small"
                                                                    label="Locked"
                                                                    variant="outlined"
                                                                ></Chip>
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        />
                                    </ListItem>
                                );
                            },
                            cellStyle: {
                                width: 200,
                            },
                        },
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Options',
                            render: rowData => {
                                return (
                                    <div>
                                        <IconButton
                                            color="primary"
                                            aria-label="download template"
                                            title="Download template"
                                            disabled={!rowData.doc}
                                            href={
                                                '/api/templates/' +
                                                rowData._id +
                                                '/download'
                                            }
                                        >
                                            <CloudDownloadIcon />
                                        </IconButton>
                                        <DeleteButton
                                            onClick={() =>
                                                deleteTemplate(rowData)
                                            }
                                        />
                                        <IconButton
                                            color="primary"
                                            aria-label="edit template"
                                            title="Edit template"
                                            onClick={() => onEdit(rowData)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <CloneButton
                                            onClone={key =>
                                                _cloneHandler(rowData, key)
                                            }
                                        />
                                    </div>
                                );
                            },
                            cellStyle: {
                                width: 200,
                            },
                        },
                    ]}
                    data={templates}
                    title="Templates"
                    onSelectionChange={handleTree}
                    detailPanel={[
                        {
                            render: rowData => {
                                if (rowData.type === 'render') {
                                    return <DetailRow template={rowData} />;
                                }
                                return null;
                            },
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
};

TypeTable.defaultProps = {
    onEdit: () => console.log('Please provide an onEdit(template) function.'),
};

TypeTable.propTypes = {
    templates: PropTypes.array,
    getStats: PropTypes.func,
    getTemplates: PropTypes.func,
    onEdit: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        templates: state.templates,
    };
};

const mapDispatchToProps = { getTemplates, getStats };

export default connect(mapStateToProps, mapDispatchToProps)(TypeTable);
