import {
    GET_PROFILE,
    GET_TEMPLATES,
    GET_TEMPLATE,
    GET_STATS,
    GET_KEYS_SUCCESS,
    ADD_KEY,
    ADD_KEY_FAIL,
    ADD_KEY_SUCCESS,
    DELETE_KEY_SUCCESS,
    GET_INFO_SUCCESS,
    UPLOAD,
    GET_UPLOAD,
    GET_UPLOAD_SUCCESS,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    RESET_UPLOAD,
    GET_UPLOADS_SUCCESS,
    GET_UPLOADS,
    SET_TEMPLATE,
    NEW_PARSER,
    ADD_TEMPLATE_SUCCESS,
    PARSE,
    PARSE_SUCCESS,
    GET_PARSED_SUCCESS,
    IMPORT_TEMPLATE,
    IMPORT_TEMPLATE_SUCCESS,
    GET_TRANSPORTS_SUCCESS,
    SEND_MESSAGE,
    CLEAR_ERROR,
} from '../actions';

const INITIAL_STATE = {
    profile: null,
    templates: null,
    // this is the current loaded template
    template: null,
    stats: null,
    loggedIn: false,
    keys: null,
    error: null,
    token: null,
    version: null,
    // determine the application is ready
    ready: false,
    // this is the uploaded file reference
    upload: null,
    // uploading
    uploading: false,
    saving: false,
    // this is the list of last uploads
    uploads: [],
    templateChanged: false,
    result: null, // result of ocr parsing
    processing: false, // parse processing
    processingId: null, // id of ocr processing
    /**
     * can be used to set a progress bar. set to 0 and 100 for bool.
     */
    progress: 0,
    transports: [],
};

export default (state = INITIAL_STATE, { type, payload, error }) => {
    console.log(type, payload, error);
    switch (type) {
        case CLEAR_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case IMPORT_TEMPLATE: {
            return {
                ...state,
                progress: 0,
            };
        }
        case IMPORT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                progress: 100,
            };
        }
        case GET_PARSED_SUCCESS: {
            return {
                ...state,
                processing: payload.data.status === 'processing',
                result:
                    payload.data.status !== 'processing' ? payload.data : null,
            };
        }
        case PARSE: {
            return {
                ...state,
                result: null,
            };
        }
        case PARSE_SUCCESS: {
            return {
                ...state,
                processing: payload.data.status === 'processing',
                processingId: payload.data.id,
            };
        }
        case ADD_TEMPLATE_SUCCESS: {
            return {
                ...state,
                templateChanged: false,
            };
        }
        case NEW_PARSER: {
            return {
                ...state,
                template: payload,
                templateChanged: false,
                progress: 100,
            };
        }
        case SET_TEMPLATE: {
            return {
                ...state,
                template: payload,
                templateChanged: true,
            };
        }
        case GET_UPLOADS: {
            return {
                ...state,
                uploads: [],
            };
        }
        case GET_UPLOADS_SUCCESS: {
            // sort by descending to get the latest first
            const descUploads = payload.data.sort(
                (a, b) => new Date(b.date) - new Date(a.date)
            );
            return {
                ...state,
                uploads: descUploads,
            };
        }
        case RESET_UPLOAD:
            return {
                ...state,
                upload: null,
                uploading: false,
            };
        case UPDATE_TEMPLATE:
            return {
                ...state,
                saving: true,
                progress: 0,
            };
        case UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                template: payload.data,
                saving: false,
                templateChanged: false,
                progress: 100,
            };
        case GET_INFO_SUCCESS:
            return {
                ...state,
                version: payload.data.version,
                ready: payload.data.success,
            };
        case DELETE_KEY_SUCCESS:
            return {
                ...state,
            };
        case UPLOAD:
            return {
                ...state,
                uploading: true,
                progress: 0,
            };
        case UPLOAD + '_SUCCESS':
            return {
                ...state,
                upload: payload.data,
                progress: 100,
                uploading: false,
            };
        case GET_UPLOAD:
            return {
                ...state,
                upload: null,
            };
        case GET_UPLOAD_SUCCESS:
            return {
                ...state,
                upload: payload.data,
            };
        case GET_TEMPLATE:
            return {
                ...state,
                template: null,
                templateChanged: false,
                progress: 0,
            };
        case GET_TEMPLATE + '_SUCCESS':
            return {
                ...state,
                template: payload.data,
                error: null,
                progress: 100,
            };
        case ADD_KEY:
            return {
                ...state,
                token: null,
            };
        case ADD_KEY_SUCCESS:
            return {
                ...state,
                token: payload.data.token,
                error: null,
            };
        case ADD_KEY_FAIL:
            throw error;
        case GET_KEYS_SUCCESS:
            return {
                ...state,
                keys: payload.data,
                error: null,
                token: payload.data.length > 0 ? state.token : null, // rm clipboard token, when all tokens are removed
            };
        case GET_PROFILE + '_FAIL':
            return {
                ...state,
                profile: null,
                loggedIn: false,
                error: 401,
            };
        case GET_PROFILE + '_SUCCESS':
            return {
                ...state,
                profile: payload.data,
                loggedIn: true,
                error: null,
            };
        case GET_TEMPLATES + '_SUCCESS':
            return {
                ...state,
                templates: payload.data,
            };
        case GET_STATS + '_SUCCESS':
            return {
                ...state,
                stats: payload.data,
            };
        case GET_TRANSPORTS_SUCCESS:
            return {
                ...state,
                transports: payload.data,
            };
        case SEND_MESSAGE + '_FAIL':
            console.log(error);
            return {
                ...state,
                error: new Error('Send message failed'),
            };
        default:
            return state;
    }
};
