import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTemplates, getStats } from './../store/actions';
import Grid from '@material-ui/core/Grid';
import { useStyles } from '../themes';
import TypeTable from '../components/templates/table';
import Help from './../components/help';
import Hidden from '@material-ui/core/Hidden';
import ConverterWidget from './../components/widgets/converter';
import StatsWidget from './../components/widgets/stats';
import { withTheme } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import NewTemplate from './../components/templates/quick';
import { useHistory } from 'react-router-dom';
import Page from './../components/layout/page';

const TemplateDescriptionComponent = props => {
    const classes = useStyles();
    const { getTemplates, getStats } = props;

    const [open, setOpen] = React.useState(false);

    const onAdd = () => {
        getTemplates();
        getStats();
    };

    return (
        <React.Fragment>
            <span onClick={() => setOpen(true)} className={classes.link}>
                Templates are available for conversion. Click here to add a
                template.
            </span>
            <NewTemplate
                open={open}
                onClose={() => setOpen(!open)}
                onSave={onAdd}
                title="New template"
            ></NewTemplate>
        </React.Fragment>
    );
};

TemplateDescriptionComponent.propTypes = {
    getTemplates: PropTypes.func.isRequired,
    getStats: PropTypes.func.isRequired,
};

const TemplateDescription = connect(null, { getTemplates, getStats })(
    TemplateDescriptionComponent
);

const Home = props => {
    const { stats, getStats, theme } = props;
    const history = useHistory();
    console.log(theme);

    /*     const [loaded, setLoaded] = React.useState(false);
    const [result, setResult] = React.useState(null);

    const handleClose = () => {
        setResult(null);
    };

    const onSuccess = res => {
        setResult(res);
    }; */

    const onEditTemplate = template => {
        if (template.type === 'parse') {
            // navigate to edit template page
            history.push('/parser/' + template._id + '/edit');
        } else if (template.type === 'form') {
            history.push('/designer/' + template._id + '/edit');
        } else if (template.type === 'mail') {
            history.push('/mailer/' + template._id + '/edit');
        } else {
            // navigate to edit template page
            history.push('/templates/' + template._id + '/edit');
        }
    };

    if (!stats) {
        getStats();
        return <div></div>;
    }

    return (
        <Page>
            <Grid container spacing={2}>
                <Grid item md={3}>
                    <StatsWidget
                        color={theme.palette.primary.dark}
                        title={stats.users}
                        icon={<AccountCircleIcon fontSize="large" />}
                        description="Users are using this service. Click here to see the user list."
                    />
                </Grid>
                <Grid item md={3}>
                    <StatsWidget
                        color={theme.palette.primary.dark}
                        title={stats.documents}
                        icon={<DescriptionIcon fontSize="large" />}
                        description="Documents are stored in our database. Click here to see a list of documents."
                    />
                </Grid>
                <Grid item md={3}>
                    <StatsWidget
                        color={theme.palette.primary.dark}
                        title={stats.templates}
                        description={<TemplateDescription />}
                    />
                </Grid>
                <Grid item md={3}>
                    <ConverterWidget />
                </Grid>
                <Grid item md={12} lg={9}>
                    <TypeTable onEdit={onEditTemplate}></TypeTable>
                </Grid>
                <Hidden mdDown>
                    <Grid item lg={3}>
                        <Help />
                    </Grid>
                </Hidden>
            </Grid>
        </Page>
    );
};

Home.propTypes = {
    theme: PropTypes.object,
    stats: PropTypes.object,
    getStats: PropTypes.func,
};

const HomeComponent = withTheme(Home);

const mapStateToProps = state => {
    return {
        stats: state.stats,
    };
};

const mapDispatchToProps = { getStats };

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
