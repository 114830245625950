import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@hobex/react-material';

const LoadingContainer = props => {
    const { loading, children } = props;

    if (loading) {
        return <Loader />;
    }

    return children;
};

LoadingContainer.defaultProps = {
    loading: false,
};

LoadingContainer.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
};

export default LoadingContainer;
