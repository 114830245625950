import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@hobex/react-material/dist/components';
import { useHistory } from 'react-router-dom';

const MenuComponent = props => {
    const { profile } = props;
    const history = useHistory();

    const handleClick = () => {
        history.push('/profile');
    };

    return (
        <React.Fragment>
            <Avatar profile={profile} onClick={handleClick} />
        </React.Fragment>
    );
};

MenuComponent.defaultProps = {
    onProfile: () => console.log('Please provide onProfile() function.'),
};

MenuComponent.propTypes = {
    profile: PropTypes.object.isRequired,
    onProfile: PropTypes.func,
};

export default MenuComponent;
