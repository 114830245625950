import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SMTPForm from './smtp';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { connect } from 'react-redux';
import { saveTransport, verifySmtp } from '../../store/actions';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const MailSender = props => {
    const classes = useStyles();
    const { color, onSave, transports, onSelect, transport, onVerify } = props;
    const [open, setOpen] = React.useState(false);
    const [selection, setSelection] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [values, setValues] = React.useState(transport);

    const _toggleSelection = () => {
        setSelection(!selection);
    };

    const _toggleModal = () => {
        setOpen(!open);
    };

    const _handleClose = () => {
        setOpen(false);
    };

    const _addHandler = async val => {
        if (onSave) {
            await onSave(val);
            setValues(val);
            _handleClose();
        }
    };

    const _selectProvider = (event, index) => {
        setSelection(false);
        setValues(transports[index]);
        if (onSelect) {
            onSelect(transports[index]);
        }
    };

    const _addNewTransport = () => {
        setValues(null);
        setSelection(false);
        setOpen(true);
    };

    return (
        <>
            <ButtonGroup
                variant="contained"
                aria-label="split button"
                ref={anchorRef}
                {...props}
                color={color}
            >
                <Button onClick={_toggleModal}>
                    {transport === null && <>Select a transport</>}
                    {transport !== null && <>{transport.name}</>}
                </Button>
                <Button color={color} size="small" onClick={_toggleSelection}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Dialog
                onClose={() => setSelection(false)}
                aria-labelledby="simple-dialog-transport"
                open={selection}
            >
                <DialogTitle>Transport</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the transport to use for this mail template.{' '}
                        <Link onClick={_addNewTransport}>Click here</Link> to
                        add a new transport.
                    </DialogContentText>
                    {transports.length > 0 && (
                        <MenuList id="split-button-menu">
                            {transports.map((option, index) => (
                                <MenuItem
                                    key={option.name}
                                    selected={
                                        transport &&
                                        option.name === transport.name
                                    }
                                    onClick={event =>
                                        _selectProvider(event, index)
                                    }
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    )}
                </DialogContent>
            </Dialog>

            <Dialog
                onClose={_handleClose}
                aria-labelledby="simple-dialog-title"
                open={open}
            >
                <DialogTitle id="simple-dialog-title">
                    {values && <>Edit Transport {values.name}</>}
                    {values === null && <>New Transport</>}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The provided settings here are used to send the emails
                        through this provider.
                    </DialogContentText>
                    <SMTPForm
                        onSubmit={_addHandler}
                        onVerify={onVerify}
                        values={values}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" form="smtp-form" color="secondary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

MailSender.defaultProps = {
    color: 'primary',
    selectedIndex: -1,
    onVerify: val =>
        console.log('Please provide verficiation func for transport.'),
};

MailSender.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'default']),
    transports: PropTypes.arrayOf(
        PropTypes.objectOf({
            name: PropTypes.string,
            from: PropTypes.string,
            type: PropTypes.string,
            host: PropTypes.string,
            port: PropTypes.number,
            secure: PropTypes.bool,
            user: PropTypes.string,
            password: PropTypes.string,
        })
    ),
    transport: PropTypes.object,
    onSave: PropTypes.func,
    onSelect: PropTypes.func,
    onVerify: PropTypes.func,
};

export { MailSender };

const mapStateToProps = (state, ownProps) => {
    return {
        transports: state.transports,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        onSave: val => dispatch(saveTransport(val)),
        onVerify: val => dispatch(verifySmtp(val)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailSender);
