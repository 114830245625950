import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    toolbar: {
        textAlign: 'right',
    },
}));
const TemplateHelp = ({ children }) => {
    const classes = useStyles();

    let help = children;
    if (!help) {
        help = (
            <React.Fragment>
                <Typography paragraph>
                    Use the widgets or the menu to navigate through the
                    application.
                </Typography>
                <Typography paragraph>
                    <strong>Conversion widget</strong> is used to convert a pdf
                    form to json.
                </Typography>

                <Typography paragraph>
                    <a href="/docs">Read the documentation</a> for api access,
                    and make sure you have an api key for your user. Click on
                    your profile to generate one.
                </Typography>
            </React.Fragment>
        );
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="h3" gutterBottom>
                Help
            </Typography>
            {help}
        </Paper>
    );
};

TemplateHelp.defaultProps = {};

TemplateHelp.propTypes = {
    children: PropTypes.any,
};

export default TemplateHelp;
