import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStats } from './../../store/actions';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { SaveButton } from '@hobex/react-material';
import { Loader } from '@hobex/react-material';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
    },
    margin: {
        marginBottom: theme.spacing(2),
    },
}));
const DetailRow = props => {
    const classes = useStyles();
    const { template, getStats } = props;
    const [state, setState] = React.useState({
        watermark: false,
        data: template.sample || JSON.stringify({}),
        processing: false,
    });

    const handleChange = (event, value) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleDataChange = event => {
        setState({ ...state, data: event.target.value });
    };

    const handleSubmit = event => {
        event.preventDefault();
        setState({ ...state, processing: true });
        console.log(state);
        let body = {
            watermark: state.watermark,
            data: JSON.parse(state.data),
        };
        // render this template
        fetch('/api/render/' + template.key, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(body),
        })
            .then(res => {
                return res.json();
            })
            .then(res => {
                window.location.href = '/downloads/' + res.id;
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setState({ ...state, processing: false });
                getStats();
            });
    };
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <TextField
                name="data"
                id="data"
                fullWidth
                label="Data"
                multiline
                rows="5"
                onChange={handleDataChange}
                helperText="JSON data"
                className={classes.margin}
                defaultValue={state.data}
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={state.watermark}
                        onChange={handleChange}
                        name="watermark"
                        color="secondary"
                    />
                }
                label="Watermark"
            />
            <SaveButton
                variant="contained"
                type="submit"
                fullWidth
                className={classes.margin}
                disabled={state.processing}
            >
                {state.processing && <Loader color="inherit" size="small" />}
                {!state.processing && 'CONVERT TO PDF'}
            </SaveButton>
        </form>
    );
};

DetailRow.defaultProps = {};

DetailRow.propTypes = {
    template: PropTypes.object.isRequired,
    getStats: PropTypes.func,
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = { getStats };

export default connect(mapStateToProps, mapDispatchToProps)(DetailRow);
