import React from 'react';
import { useDropzone } from 'react-dropzone';
import RootRef from '@material-ui/core/RootRef';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@hobex/react-material';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        cursor: 'pointer',
        '& Mui-focused': {
            borderColor: 'orange',
        },
    },
    paperWithFiles: {
        backgroundColor: theme.palette.grey[900],
        color: 'white',
    },
    toolbar: {
        textAlign: 'right',
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    margin: {
        marginRight: theme.spacing(2),
    },
}));

const PaperDropzone = props => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            props.onDrop(acceptedFiles);
        },
        multiple: false,
    });
    const { ref, ...rootProps } = getRootProps();
    const classes = useStyles();
    const { text, uploading, files, onChange } = props;

    const removeFile = () => {
        props.onDrop(null);
    };

    onChange(files);

    if (files && files.length > 0) {
        return (
            <Paper className={classes.paperWithFiles} elevation={1}>
                <List>
                    {files.map(f => {
                        return (
                            <ListItem key={f.id}>
                                <ListItemText primary={f.originalFileName} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => removeFile(f)}
                                        color="inherit"
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        );
    }

    if (!uploading) {
        return (
            <RootRef rootRef={ref}>
                <Paper {...rootProps} className={classes.paper} elevation={1}>
                    <input {...getInputProps()} />
                    <Typography
                        variant="subtitle1"
                        className={classes.wrapIcon}
                    >
                        <CloudUploadIcon className={classes.margin} />
                        <span>{text}</span>
                    </Typography>
                </Paper>
            </RootRef>
        );
    } else {
        return (
            <Paper className={classes.paper} elevation={1}>
                <Loader />
            </Paper>
        );
    }
};

PaperDropzone.defaultProps = {
    text: "Drag 'n' drop some files here, or click to select files",
    onDrop: () => console.log('Please provide an onDrop(files) function.'),
    uploading: false,
    onChange: () => console.log('Please provide an onChange(files) function'),
};

PaperDropzone.propTypes = {
    text: PropTypes.string,
    onDrop: PropTypes.func,
    uploading: PropTypes.bool,
    files: PropTypes.array,
    onChange: PropTypes.func,
};

export default PaperDropzone;
