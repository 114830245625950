export const GET_PROFILE = 'GET_PROFILE';
export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const GET_STATS = 'GET_STATS';
export const GET_KEYS = 'GET_KEYS';
export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS';
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_KEY = 'ADD_KEY';
export const ADD_KEY_SUCCESS = 'ADD_KEY_SUCCESS';
export const ADD_KEY_FAIL = 'ADD_KEY_FAIL';
export const DELETE_KEY = 'DELETE_KEY';
export const DELETE_KEY_SUCCESS = 'DELETE_KEY_SUCCESS';
export const GET_INFO = 'GET_INFO';
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS';
export const UPLOAD = 'UPLOAD_TEMPLATE';
export const GET_UPLOAD = 'GET_UPLOAD';
export const GET_UPLOAD_SUCCESS = 'GET_UPLOAD_SUCCESS';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const RESET_UPLOAD = 'RESET_UPLOAD';
export const GET_UPLOADS = 'GET_UPLOADS';
export const GET_UPLOADS_SUCCESS = 'GET_UPLOADS_SUCCESS';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const NEW_PARSER = 'NEW_TEMPLATE';
export const PARSE = 'PARSE';
export const PARSE_SUCCESS = 'PARSE_SUCCESS';
export const GET_PARSED = 'GET_PARSED';
export const GET_PARSED_SUCCESS = 'GET_PARSED_SUCCESS';
export const IMPORT_TEMPLATE = 'IMPORT_TEMPLATE';
export const IMPORT_TEMPLATE_SUCCESS = 'IMPORT_TEMPLATE_SUCCESS';
export const GET_TRANSPORTS = 'GET_TRANSPORTS';
export const GET_TRANSPORTS_SUCCESS = 'GET_TRANSPORTS_SUCCESS';
export const ADD_TRANSPORT = 'ADD_TRANSPORT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const VERIFY_SMTP = 'VERIFY_SMTP';

export const verifySmtp = params => {
    return {
        type: VERIFY_SMTP,
        payload: {
            request: {
                url: `/api/check/transports/smtp`,
                method: 'POST',
                data: params,
            },
        },
    };
};

export const sendMessage = params => {
    const formData = new FormData();
    formData.append('to', params.to);
    formData.append('cc', params.cc);
    formData.append('bcc', params.bcc);

    const data = JSON.parse(params.test_data);
    if (data) {
        Object.keys(data).map(f => {
            formData.append(`v:${f}`, JSON.stringify(data[f]));
        });
    }

    return {
        type: SEND_MESSAGE,
        payload: {
            request: {
                url: `/api/messages?template=${params.template}`,
                method: 'POST',
                data: formData,
            },
        },
    };
};

export const getTransports = () => {
    return {
        type: GET_TRANSPORTS,
        payload: {
            request: {
                url: '/api/config/transports',
            },
        },
    };
};

export const newParser = () => {
    return {
        type: NEW_PARSER,
        payload: {
            type: 'parse',
            key: '',
            name: '',
            description: '',
            fields: {},
            isNew: true,
        },
    };
};

export const saveTransport = params => {
    return async dispatch => {
        // create or update
        if (!params._id) {
            // dispatch add
            await dispatch({
                type: ADD_TRANSPORT,
                payload: {
                    request: {
                        url: '/api/config/transports',
                        method: 'POST',
                        data: params,
                    },
                },
            });
        } else {
            // dispatch add
            await dispatch({
                type: ADD_TRANSPORT,
                payload: {
                    request: {
                        url: `/api/config/transports/${params._id}`,
                        method: 'PUT',
                        data: params,
                    },
                },
            });
        }

        await dispatch(getTransports());
    };
};

export const setTemplate = template => {
    return {
        type: SET_TEMPLATE,
        payload: template,
    };
};

export const getUpload = id => {
    return {
        type: GET_UPLOAD,
        payload: {
            request: {
                url: '/api/uploads/' + id,
            },
        },
    };
};

export const getUploads = (count = 10, user = null) => {
    return async dispatch => {
        // dispatch to get latest uploads and retrieve the first to get also the meta information

        let res;
        if (!user) {
            res = await dispatch({
                type: GET_UPLOADS,
                payload: {
                    request: {
                        url: '/api/uploads?count=' + count,
                    },
                },
            });
        } else {
            res = await dispatch({
                type: GET_UPLOADS,
                payload: {
                    request: {
                        url: '/api/profile/uploads?count=' + count,
                    },
                },
            });
        }

        // dispatch get upload if files are found
        const { payload } = res;
        if (payload.data.length > 0) {
            dispatch(getUpload(payload.data[0].id));
        }
    };
};

export const resetUpload = () => {
    return {
        type: RESET_UPLOAD,
    };
};

const justUpload = formdata => {
    return {
        type: UPLOAD,
        payload: {
            request: {
                url: '/api/uploads',
                method: 'POST',
                data: formdata,
            },
        },
    };
};

export const getParserResult = (key, id) => {
    return {
        type: GET_PARSED,
        payload: {
            request: {
                url: `/api/parse/${key}/${id}`,
            },
        },
    };
};

export const parse = (key, file) => {
    // create formdata for file upload
    const formData = new FormData();
    formData.append('file', file);

    return async dispatch => {
        const { payload } = await dispatch({
            type: PARSE,
            payload: {
                request: {
                    url: '/api/parse/' + key,
                    method: 'POST',
                    data: formData,
                },
            },
        });
        // retrieve id from parse api
        const id = payload.data.id;

        // try to get result each 250ms
        const retry = setInterval(async () => {
            const { payload } = await dispatch(getParserResult(key, id));
            // clear the interval on end of processing
            if (payload.data.status !== 'processing') {
                clearInterval(retry);
            }
        }, 500);
    };
};

export const postUpload = files => {
    const f = files[0];

    // create formdata for file upload
    const formData = new FormData();
    formData.append('file', f);

    // dispatch upload and retrieve new upload data afterwards
    return async dispatch => {
        const { payload } = await dispatch(justUpload(formData));
        return dispatch(getUpload(payload.data.id));
    };
};

export const getInfo = () => {
    return {
        type: GET_INFO,
        payload: {
            request: {
                url: '/heartbeat',
            },
        },
    };
};

export const getProfile = () => {
    return {
        type: GET_PROFILE,
        payload: {
            request: {
                url: '/api/profile',
            },
        },
    };
};

export const updateTemplate = template => {
    return {
        type: UPDATE_TEMPLATE,
        payload: {
            request: {
                url: '/api/templates/' + template._id,
                method: 'PUT',
                data: template,
            },
        },
    };
};

export const getTemplate = id => {
    return async dispatch => {
        const { payload } = await dispatch({
            type: GET_TEMPLATE,
            payload: {
                request: {
                    url: '/api/templates/' + id,
                },
            },
        });
        if (payload.data.doc) {
            console.log('get upload', payload.data.doc);
            dispatch(getUpload(payload.data.doc));
        }
        return payload;
    };
};

export const importTemplate = (id, file) => {
    // create formdata for file upload
    const formData = new FormData();
    formData.append('file', file);
    return async dispatch => {
        // dispatch the import process and wait for it
        await dispatch({
            type: IMPORT_TEMPLATE,
            payload: {
                request: {
                    url: `/api/templates/${id}/import`,
                    method: 'POST',
                    data: formData,
                },
            },
        });
        // refresh the template
        dispatch(getTemplate(id));
    };
};

export const getTemplates = () => {
    return {
        type: GET_TEMPLATES,
        payload: {
            request: {
                url: '/api/templates',
            },
        },
    };
};

export const getStats = () => {
    return {
        type: GET_STATS,
        payload: {
            request: {
                url: '/api/stats',
            },
        },
    };
};

export const getKeys = () => {
    return {
        type: GET_KEYS,
        payload: {
            request: {
                url: '/api/profile/keys',
            },
        },
    };
};

export const addKey = key => {
    return {
        type: ADD_KEY,
        payload: {
            request: {
                url: '/api/profile/keys',
                method: 'POST',
                data: key,
            },
        },
    };
};

export const deleteKey = key => {
    return {
        type: DELETE_KEY,
        payload: {
            request: {
                url: '/api/profile/keys/' + key,
                method: 'DELETE',
            },
        },
    };
};

export const saveTemplate = template => {
    return async dispatch => {
        if (template._id) {
            await dispatch(updateTemplate(template));
        } else {
            await dispatch({
                type: ADD_TEMPLATE,
                payload: {
                    request: {
                        url: '/api/templates',
                        method: 'POST',
                        data: template,
                    },
                },
            });
        }
        dispatch(getTemplates());
    };
};

export const newField = page => {
    const name = Date.now();
    return {
        [name]: {
            page,
            jsonField: name,
            position: {
                height: 80,
                width: 300,
                x: 0,
                y: 0,
            },
            trim: true,
        },
    };
};
