import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
const Progress = props => {
    const classes = useStyles();
    const { open } = props;
    return (
        <Backdrop className={classes.backdrop} open={open < 100}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

Progress.defaultProps = {};

Progress.propTypes = {
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default Progress;
