import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const ErrorDialog = props => {
    const { error, onDismiss } = props;

    const [open, setOpen] = React.useState(error !== null);

    React.useEffect(() => {
        setOpen(error !== null);
    }, [error]);

    const _handleClose = () => {
        // dispatch error to null
        setOpen(false);
        onDismiss();
    };

    if (!open) return <></>;

    return (
        <Dialog open={open} onClose={_handleClose}>
            <DialogTitle id="alert-dialog-title" color="error">
                {'Error'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" color="error">
                    {error && error.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={_handleClose}
                    color="secondary"
                    variant="contained"
                >
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ErrorDialog.defaultProps = {
    onDismiss: () =>
        console.log(
            'Please provide onDismiss or your are not able to dismiss errors.'
        ),
};

ErrorDialog.propTypes = {
    error: PropTypes.any,
    onDismiss: PropTypes.func.isRequired,
};

export default ErrorDialog;
