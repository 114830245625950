import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LiveChat from '@hobex/react-rocket-livechat';

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 100,
        textAlign: 'center',
    },
}));
const Footer = props => {
    const { version } = props;
    const classes = useStyles();
    const fields = {
        app: 'carbone',
        version,
    };

    return (
        <div className={classes.root}>
            <Typography variant="body2" component="span" paragraph>
                <a
                    href="https://git.brunn.hobex.at/services/carbone"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Carbone <i>v{version}</i>
                </a>
                <br />
                &copy; Hobex Payment Systems
                <br />
                Made with <FavoriteIcon style={{ fontSize: 14 }} /> in Vienna,
                Austria
            </Typography>
            <LiveChat
                department="development"
                title="CARBONE SUPPORT"
                fields={fields}
            />
        </div>
    );
};

Footer.defaultProps = {
    version: '0.0.1-dev',
};

Footer.propTypes = {
    version: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        version: state.version,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
