import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditForm from './form';

const EditTemplate = props => {
    const { open, onClose, title, onSave } = props;
    const [template, setTemplate] = React.useState(null);

    const onSubmit = data => {
        setTemplate(data);
        console.log(data);
        let api = {
            key: data.key,
            description: data.description,
            template: data.template.id,
            version: 1,
        };

        fetch('/api/templates', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify(api),
        }).then(res => {
            console.log(res);
            onClose();
            onSave(res);
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth="true">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <EditForm template={template} onSubmit={onSubmit} />
            </DialogContent>
        </Dialog>
    );
};

EditTemplate.defaultProps = {
    open: false,
    onClose: () => console.log('Please provide a onClose() function.'),
    onSave: () => console.log('Please provide a onClose() function.'),
    title: 'Template',
};

EditTemplate.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    title: PropTypes.string,
};

export default EditTemplate;
