import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Uploader from './../uploader';
import { useForm, Controller } from 'react-hook-form';
import { SaveButton } from '@hobex/react-material';

const useStyles = makeStyles(theme => ({
    root: {},
    margin: {
        marginBottom: theme.spacing(2),
    },
    saveButton: {
        marginBottom: theme.spacing(2),
        lineHeight: '48px',
        fontSize: '24px',
    },
}));
const TemplateForm = props => {
    const classes = useStyles();
    const [uploading, setUploading] = React.useState(false);
    const [template, setTemplate] = React.useState(null);
    const { register, handleSubmit, errors, control } = useForm();
    const { onSubmit } = props;

    const submitHandler = data => {
        onSubmit(data);
    };

    const onDrop = files => {
        if (!files) {
            setTemplate(null);
            return;
        }
        setUploading(true);
        const f = files[0];
        const formData = new FormData();
        formData.append('file', f);

        setTimeout(() => {
            fetch('/api/uploads', {
                method: 'POST',
                body: formData,
            })
                .then(res => res.json())
                .then(success => {
                    setTemplate(success);
                })
                .finally(() => {
                    setUploading(false);
                });
        }, 250);
    };

    return (
        <form
            className={classes.root}
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
        >
            <div className={classes.margin}>
                <Controller
                    as={
                        <Uploader
                            uploading={uploading}
                            onDrop={onDrop}
                            files={template ? [template] : []}
                        />
                    }
                    rules={{ required: true }}
                    name="template"
                    control={control}
                    onChange={([files]) => {
                        return files ? files[0] : null;
                    }}
                />
            </div>
            <TextField
                id="template_key"
                name="key"
                fullWidth
                inputRef={register({
                    required: true,
                    pattern: /^[a-z0-9-]+$/g,
                    maxLength: 20,
                })}
                error={errors.key}
                label="Key"
                required
                className={classes.margin}
                helperText="Logical key for template. Lowercase, number and hyphens for e.g. example-key1. Max. 20 digits."
            />
            <TextField
                className={classes.margin}
                name="description"
                id="template_description"
                fullWidth
                label="Description"
                inputRef={register({ required: false })}
                multiline
                rows="5"
                helperText="Description for usage."
            />
            <SaveButton
                variant="contained"
                type="submit"
                fullWidth
                className={classes.saveButton}
            >
                SAVE
            </SaveButton>
        </form>
    );
};

TemplateForm.defaultProps = {
    onSubmit: () =>
        console.log('Please provide an onSubmit(template) function.'),
    template: {},
};

TemplateForm.propTypes = {
    onSubmit: PropTypes.func,
    template: PropTypes.object,
};

export default TemplateForm;
