import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Uploader from './../../uploader';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './../../../themes';
import { SaveButton } from '@hobex/react-material';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const EditTemplate = props => {
    const classes = useStyles();
    const { register, handleSubmit, errors, control, setValue } = useForm();
    const { onSubmit, template, onUpload, upload, saving, uploading } = props;
    const [readonly, setReadonly] = React.useState(template.readonly);

    const onDrop = files => {
        onUpload(files);
    };

    const submitHandler = data => {
        console.log(data);
        onSubmit(data);
    };

    return (
        <form
            className={classes.root}
            onSubmit={handleSubmit(submitHandler)}
            noValidate
            autoComplete="off"
        >
            <Typography variant="h6" gutterBottom>
                Edit template #{template.key}
            </Typography>
            <hr />
            <div className={classes.gutterBottom}>
                <Controller
                    as={
                        <Uploader
                            onDrop={onDrop}
                            files={upload ? [upload] : []}
                            uploading={uploading}
                        />
                    }
                    rules={{ required: true }}
                    name="template"
                    control={control}
                    onChange={([files]) => {
                        return files ? files[0] : null;
                    }}
                />
            </div>
            <TextField
                id="template_key"
                name="key"
                fullWidth
                inputRef={register({
                    required: true,
                    pattern: /^[a-z0-9-]+$/g,
                    maxLength: 20,
                })}
                error={errors.key}
                label="Key"
                required
                InputProps={{
                    readOnly: true,
                }}
                className={classes.gutterBottom}
                defaultValue={template.key}
                helperText="Caution: You cannot change the key, because all api requests will fail."
            />
            <TextField
                id="template_name"
                name="name"
                fullWidth
                inputRef={register({
                    maxLength: 40,
                })}
                InputProps={{
                    readOnly: readonly,
                }}
                error={errors.name}
                label="Custom Name"
                className={classes.gutterBottom}
                defaultValue={template.name}
                helperText="Give your template a custom name."
            />
            <TextField
                className={classes.gutterBottom}
                name="description"
                id="template_description"
                fullWidth
                label="Description"
                inputRef={register({ required: false })}
                InputProps={{
                    readOnly: readonly,
                }}
                multiline
                rows="5"
                defaultValue={template.description}
                helperText="Description for usage."
            />
            <TextField
                className={classes.gutterBottom}
                name="sample"
                id="template_sampledata"
                fullWidth
                label="Sample"
                InputProps={{
                    readOnly: readonly,
                }}
                inputRef={register({ required: false })}
                multiline
                rows="5"
                defaultValue={template.sample}
                helperText="Sample data for this template."
            />
            <FormControl
                className={classes.gutterBottom}
                variant="outlined"
                fullWidth
            >
                <Select
                    native
                    defaultValue={template.storage}
                    disabled={readonly}
                    inputProps={{
                        name: 'storage',
                        id: 'template_storage',
                    }}
                    inputRef={register({ required: true })}
                    onChange={ev => setValue('storage', ev.target.value)}
                >
                    <option value="grid">Grid (legacy)</option>
                    <option value="s3">S3</option>
                </Select>
                <FormHelperText>Storage Type</FormHelperText>
            </FormControl>
            <FormControl className={classes.gutterBottom} fullWidth>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={readonly}
                            inputRef={register()}
                            onChange={ev => {
                                setValue('readonly', ev.target.checked);
                                setReadonly(ev.target.checked);
                            }}
                            name="readonly"
                            color="primary"
                        />
                    }
                    label="Write Protected"
                />
            </FormControl>
            <SaveButton
                type="submit"
                fullWidth
                className={classes.saveButton}
                disabled={saving}
            >
                SAVE
            </SaveButton>
        </form>
    );
};

EditTemplate.defaultProps = {
    onSubmit: () => console.log('Please provide a onSubmit(data) function.'),
    saving: false,
    uploading: false,
};

EditTemplate.propTypes = {
    template: PropTypes.object.isRequired,
    upload: PropTypes.object,
    onUpload: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    uploading: PropTypes.bool,
    saving: PropTypes.bool,
};

export default EditTemplate;
