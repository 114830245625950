import React from 'react';
import EditTemplateContainer from './../../containers/templates/edit';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './../../themes';

const EditTemplatePage = props => {
    const classes = useStyles();
    return (
        <div className={classes.content}>
            <Grid container spacing={2}>
                <Grid item sm={12} md={9}>
                    <Paper className={classes.paper}>
                        <EditTemplateContainer id={props.match.params.id} />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default EditTemplatePage;
