import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import Reactotron from './../ReactotronConfig';

const client = axios.create({
    //all axios can be used, shown in axios documentation
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// enable reactotron only in non production builds
const middlewares = [];
middlewares.push(applyMiddleware(thunk, axiosMiddleware(client)));
if (process.env.NODE_ENV !== 'production') {
    middlewares.push(Reactotron.createEnhancer());
}

const store = createStore(rootReducer, composeEnhancers(...middlewares));

export default store;
