import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendMessage } from '../../store/actions';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './../../themes';
import Button from '@material-ui/core/Button';
import AceEditor from 'react-ace';

const Test = props => {
    const { onSend, values, profile } = props;
    const classes = useStyles();

    const [data, setData] = React.useState({
        ...values,
        to: profile ? profile.email : '',
    });

    const _submitHandler = () => {
        console.log(data);
        if (onSend) {
            onSend(data);
        }
    };

    const _changeHandler = ev => {
        const fieldName = ev.target.name;
        setData({
            ...data,
            [fieldName]: ev.target.value,
        });
    };

    return (
        <>
            <TextField
                id="smtp_to"
                name="to"
                onChange={_changeHandler}
                defaultValue={data.to}
                fullWidth
                label="To"
                className={classes.gutterBottom}
                variant="outlined"
            />
            <TextField
                id="smtp_cc"
                name="cc"
                onChange={_changeHandler}
                defaultValue={data.cc}
                fullWidth
                label="CC"
                className={classes.gutterBottom}
                variant="outlined"
            />
            <TextField
                id="smtp_bcc"
                name="bcc"
                onChange={_changeHandler}
                defaultValue={data.bcc}
                fullWidth
                label="BCC"
                className={classes.gutterBottom}
                variant="outlined"
            />
            <AceEditor
                mode="json"
                theme="github"
                name="test_data"
                className={classes.gutterBottom}
                width="auto"
                onChange={val =>
                    _changeHandler({
                        target: {
                            name: 'test_data',
                            value: val,
                        },
                    })
                }
                value={data.test_data}
                defaultValue={data.test_data}
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                    showPrintMargin: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                }}
            />
            <Button
                color="secondary"
                variant="contained"
                onClick={_submitHandler}
            >
                Send
            </Button>
        </>
    );
};

Test.defaultProps = {
    values: null,
};

Test.propTypes = {
    onSend: PropTypes.func,
    values: PropTypes.object,
    profile: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSend: params => dispatch(sendMessage(params)),
    };
};

export { Test };

export default connect(mapStateToProps, mapDispatchToProps)(Test);
