import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Progress from './../components/progress';
import Page from '../components/layout/page';
import Paper from '@material-ui/core/Paper';
import { Breadcrumbs } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './../themes';
import Grid from '@material-ui/core/Grid';
import Form from './../components/mailer/form';
import Button from '@material-ui/core/Button';
import Help from './../components/help';
import HandlebarsHelp from './../components/mailer/help/handlebars';
import { saveTemplate, getTemplate, getTransports } from '../store/actions';
import { useParams } from 'react-router-dom';
import Provider from './../components/mailer/sender';

const Mailer = props => {
    const { onSave, getTemplate, dispatch } = props;
    const { id } = useParams();
    const classes = useStyles();
    const [help, setHelp] = React.useState(<Help />);
    const [loaded, setLoaded] = React.useState(false);
    const [values, setValues] = React.useState(null);
    const [saving, setSaving] = React.useState(false);
    const [transport, setTransport] = React.useState(null);

    // effect on id change. null or selected template
    React.useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);

            // get all transports
            await dispatch(getTransports());

            // load only when id is in params
            if (id) {
                try {
                    const { data: t } = await getTemplate();
                    // set current transport
                    setTransport(t.transport || null);

                    // set the form values according to subcomponent
                    setValues({
                        ...t,
                        subject: t.fields && t.fields.subject,
                        text: t.fields && t.fields.text,
                        html: t.fields && t.fields.html,
                    });
                } catch (err) {
                    console.error(err);
                }
            } else {
                setValues(null);
            }
            // give the progress some time to show
            setTimeout(() => {
                setLoaded(true);
            }, 250);
        };

        fetchData();
    }, [id]);

    /**
     * Submithandler of form in sub component
     * @param {*} values
     */
    const _submitHandler = async values => {
        // spread new values and set type to mail
        const template = { ...values };
        template.type = 'mail';
        template.transport = transport && transport._id;
        template.fields = {
            subject: values.subject,
            text: values.text,
            html: values.html,
        };
        // call save template
        if (onSave) {
            setSaving(true);
            await onSave(template);
            setSaving(false);
        }
    };

    /**
     * Focus handler for help
     *
     * @param {*} field
     */
    const _focusHandler = field => {
        switch (field) {
            case 'text':
            case 'html':
                setHelp(<HandlebarsHelp />);
                break;

            default:
                setHelp(<Help />);
                break;
        }
    };

    const _selectTransport = t => {
        setTransport(t);
    };

    // show progress until loaded
    if (!loaded) {
        return <Progress open={loaded ? 100 : 0} />;
    }

    return (
        <React.Fragment>
            <Paper elevation={0} className={classes.paper}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                >
                    <Link color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="textPrimary">Messages</Typography>
                    {values && values.key && (
                        <Typography color="textPrimary">
                            {values.key.toUpperCase()}
                        </Typography>
                    )}
                </Breadcrumbs>
            </Paper>
            <Page>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Paper className={classes.root}>
                            <Grid container className={classes.paperHeader}>
                                <Grid item className={classes.root}>
                                    <Breadcrumbs
                                        aria-label="breadcrumb"
                                        className={classes.breadcrumb}
                                    >
                                        <Link color="inherit" href="/">
                                            Messages
                                        </Link>
                                        {values && values.key && (
                                            <Typography color="white">
                                                {values.key.toUpperCase()}
                                            </Typography>
                                        )}
                                        {!values && (
                                            <Typography color="white">
                                                New
                                            </Typography>
                                        )}
                                    </Breadcrumbs>
                                </Grid>
                                <Grid item className={classes.right}>
                                    <Provider
                                        className={classes.gutterRight}
                                        color="default"
                                        transport={transport}
                                        onSelect={_selectTransport}
                                    />

                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        form="mailer-form"
                                        disabled={saving || !transport}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                            <div className={classes.paper}>
                                <Form
                                    values={values}
                                    onSubmit={_submitHandler}
                                    onFocus={_focusHandler}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        {help}
                    </Grid>
                </Grid>
            </Page>
        </React.Fragment>
    );
};

Mailer.defaultProps = {};

Mailer.propTypes = {
    onSave: PropTypes.func.isRequired,
    getTemplate: PropTypes.func,
    dispatch: PropTypes.func,
    transports: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    return {
        transports: state.transports,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        onSave: template => dispatch(saveTemplate(template)),
        getTemplate: async () =>
            dispatch(getTemplate(ownProps.match.params.id)),
    };
};

export { Mailer };

export default connect(mapStateToProps, mapDispatchToProps)(Mailer);
