import React from 'react';
import PropTypes from 'prop-types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const CloneButton = props => {
    const { icon, title, onClone } = props;
    const [open, setOpen] = React.useState(false);
    const [key, setKey] = React.useState('');

    const _toggleDialog = () => {
        setOpen(!open);
    };

    const _handleClose = () => {
        setOpen(false);
    };

    const _handleClone = () => {
        if (onClone) {
            onClone(key);
        }
        setOpen(false);
    };

    return (
        <>
            <IconButton
                color="primary"
                aria-label="clone template"
                title="Clone template"
                onClick={_toggleDialog}
            >
                {icon}
            </IconButton>
            <Dialog
                open={open}
                onClose={_handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please give your new template a unique key. An already
                        assigned key cannot be used. Please be aware of that.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        onChange={ev => setKey(ev.currentTarget.value)}
                        margin="dense"
                        id="key"
                        name="key"
                        label="Key"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={_handleClone} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

CloneButton.defaultProps = {
    icon: <FileCopyIcon />,
    title: 'Clone',
    onClone: () => console.log('Please provide onClone(key) func.'),
};

CloneButton.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    onClone: PropTypes.func,
};

export default CloneButton;
