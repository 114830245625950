import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import EqualizerIcon from '@material-ui/icons/Equalizer';

// contrast color for background
/* const getContrast50 = hexcolor => {
    return parseInt(hexcolor.replace('#', ''), 16) > 0xffffff / 2
        ? 'black'
        : 'white';
};
 */
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    widget: props => ({
        backgroundColor: props.color,
        padding: theme.spacing(2),
        // color: getContrast50(props.color),
        color: 'white',
        height: 120,
        overflow: 'hidden',
    }),
    icon: {
        position: 'absolute',
        height: 100,
    },
    body: {
        marginLeft: 60,
    },
}));
const WidgetContainer = props => {
    const { icon, title, description } = props;
    const classes = useStyles(props);
    return (
        <Paper className={classes.widget}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.body}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body2">{description}</Typography>
            </div>
        </Paper>
    );
};

WidgetContainer.defaultProps = {
    color: 'orange',
    icon: <EqualizerIcon fontSize="large" />,
};

WidgetContainer.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string.isRequired,
    description: PropTypes.node,
};

export default WidgetContainer;
