import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfile, getInfo, CLEAR_ERROR } from './store/actions';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import themes from '@hobex/react-material/dist/themes';
import { Loader, Auth } from '@hobex/react-material';
import { AppBar } from '@hobex/react-material';
import Menu from './components/menu';
import Home from './pages/home';
import EditTemplate from './pages/templates/edit';
import Mailer from './pages/mailer';
import Footer from './components/footer';
import 'typeface-roboto';
import 'material-icons/iconfont/material-icons.css';
import { isIE } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import AppInit from './containers/loading';
import Navbar from './components/nav/menu';
import ErrorDialog from './components/error';

const theme = themes.default;
const LoadingMessage = () => <Loader />;
const Profile = React.lazy(() => import('./pages/profile'));
const Doc = React.lazy(() => import('./pages/docs'));
const ParserWizard = React.lazy(() => import('./pages/parser'));
const Designer = React.lazy(() => import('./pages/designer'));

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20,
        marginBottom: 70, // height of footer is 60, add some padding
    },
    content: props => ({
        flex: 1,
        marginLeft: 0,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
    }),
}));

export const App = props => {
    const { profile, getProfile, getInfo, error, ready, dispatch } = props;
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        // redirect to login
        if (error === 401) {
            window.location.href = '/auth/login';
            return;
        }

        // fetch profile on no error
        if (!profile) {
            getProfile();
            return;
        }

        // set loading to false, to show application
        setTimeout(() => {
            setLoading(false);
        }, 250);
    });

    const _dismissErrorHandler = () => {
        dispatch({ type: CLEAR_ERROR });
    };

    if (!ready) {
        getInfo();
        return <LoadingMessage />;
    }

    if (isIE)
        return <div> IE is not supported. Download Chrome/Opera/Firefox </div>;

    return (
        <ThemeProvider theme={theme}>
            <React.Suspense fallback={<LoadingMessage />}>
                <CssBaseline />
                <Router basename="/">
                    <Auth loggedIn={profile !== null}>
                        <AppInit loading={loading}>
                            <AppBar
                                title="Carbone"
                                leftComponent={<Navbar />}
                                rightComponent={<Menu profile={profile} />}
                            />
                            <Switch>
                                <Route exact path="/" component={Home}></Route>
                                <Route
                                    path="/documents"
                                    exact
                                    component={Doc}
                                ></Route>
                                <Route
                                    path="/mailer"
                                    exact
                                    component={Mailer}
                                />
                                <Route
                                    path="/mailer/:id/edit"
                                    exact
                                    component={Mailer}
                                ></Route>
                                <Route
                                    path="/profile"
                                    exact
                                    component={Profile}
                                ></Route>
                                <Route
                                    path="/parser"
                                    exact
                                    component={ParserWizard}
                                />
                                <Route
                                    path="/parser/:id/edit"
                                    exact
                                    component={ParserWizard}
                                />
                                <Route
                                    path="/templates/:id/edit"
                                    exact
                                    component={EditTemplate}
                                ></Route>
                                <Route
                                    path="/designer"
                                    exact
                                    component={Designer}
                                ></Route>
                                <Route
                                    path="/designer/:id/edit"
                                    exact
                                    component={Designer}
                                ></Route>
                            </Switch>
                            <Footer />
                            <ErrorDialog
                                error={error}
                                onDismiss={_dismissErrorHandler}
                            />
                        </AppInit>
                    </Auth>
                </Router>
            </React.Suspense>
        </ThemeProvider>
    );
};

App.propTypes = {
    profile: PropTypes.object,
    error: PropTypes.object,
    dispatch: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        profile: state.profile,
        error: state.error,
        ready: state.ready,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        getProfile: () => dispatch(getProfile()),
        getInfo: () => dispatch(getInfo()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
