import React from 'react';
import Help from '../../help';
import Typography from '@material-ui/core/Typography';

const HelpHandleBars = props => {
    return (
        <Help>
            <Typography>
                Use the handlebars syntax to create a template. Visit{' '}
                <a
                    href="https://handlebarsjs.com/guide/"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://handlebarsjs.com/guide/
                </a>{' '}
                for more information.
            </Typography>
        </Help>
    );
};

HelpHandleBars.propTypes = {};

export default HelpHandleBars;
